<template>
  <div class="workPlan classsicCase A4Container flexCloumnSingleCenter" style="height: inherit;page-break-after: always;">
    <div class="title flexCenter">实习管理典型案例</div>
    <div class="form textIndent2" style="min-height: calc(29.7cm - 100px);">
      <div v-html="dataInfo.case_content"></div>
      <!-- {{ dataInfo.case_content || '-' }} -->
      <div class="posDate">{{ dataInfo.report_time ||'' }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'cover',
  props: {
    dataInfo: {
      type: Object,
      default: {}
    }
  }
}
</script>
<style lang="less" type="text/less">
.classsicCase {
  p {
    margin: 0;
  }
}
</style>