<template>
  <div>
    <div class="pdfok" id="pdfok" v-if="pdfok"></div>
    <div id="printMe" style=" width: 21cm; margin: 0 auto;border: 0;">
      <ClassicCase v-for="(item,index) in dataList" :dataInfo="item" :key="index"></ClassicCase>
    </div>

    <el-button v-if="false" v-print="'#printMe'" type="primary" style="position: fixed;top: 0;left: 0;z-index: 999;">打印
    </el-button>
  </div>
</template>

<script>
import ClassicCase from "./com_tch/classicCase2";

export default {
  name: "export",
  components: {
    ClassicCase,
  },
  data() {
    return {
      pdfok: false,
      show_dev: true,
      show_dev1: true,
      printObj: {
        id: "printMe",
        popTitle: "",
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      userid: "",
      cover: {},
      rolls: [],
      stu_kaohe: [],
      stu_lianxi: [],
      shixi_anli: [{}],
      youxiu_shixisheng: [],
      stu_weiji: [],
      fanxiao: [],
      dataInfo: {},
      dataList: [],
    };
  },
  mounted() {
    let userid = this.getParams("id");
    let pmid = this.getParams("pmid");
    let reportMonth = this.getParams('report_month')
    this.getAllPickerVal(pmid).then(rr => {
      this.getDataList(userid, pmid,reportMonth, rr);
    });
  },
  methods: {
    getAllPickerVal(pmid) {
      return new Promise(resolve => {
        let arrForm = [],
          arrState = [];
        //巡访形式&&巡访情况
        this.$http
          .post("/api/sys_param_list", {
            param_type: "'VISIT_FORM','VISIT_SITUATION'",
            pmid: parseInt(pmid)
          })
          .then(res => {
            res.data &&
              res.data.map((item, index, arr) => {
                if (item.param_type == "VISIT_FORM") {
                  arrForm.push(item);
                }
                if (item.param_type == "VISIT_SITUATION") {
                  arrState.push(item);
                }
              });
            resolve({
              arrForm,
              arrState
            });
          });
      });
    },

    //过滤特殊字符, 校验所有输入域是否含有特殊符号
    stripscript(s) {
      var mdash = "&mdash;";
      var nbsp = "&nbsp;";
      var quot = "&quot;";
      var ldquo = "&ldquo;";
      var rdquo = "&rdquo;";
      var amp = "&amp;";
      var lt = "&lt;";
      var gt = "&gt;";

      var rs = "";
      rs = s.replace(new RegExp(mdash, "g"), "-");
      rs = rs.replace(new RegExp(nbsp, "g"), "");
      rs = rs.replace(new RegExp(quot, "g"), '"');
      rs = rs.replace(new RegExp(ldquo, "g"), "”");
      rs = rs.replace(new RegExp(rdquo, "g"), "“");
      rs = rs.replace(new RegExp(amp, "g"), "&");
      rs = rs.replace(new RegExp(lt, "g"), "<");
      rs = rs.replace(new RegExp(gt, "g"), ">");
      return rs;
    },
    getDataList(userid, pmid, reportMonth, rr) {
      let _this = this;
      let arrForm = rr ? rr.arrForm : [];
      this.$http
        .post("/api/getTypicalCaseData", {
          class_id: parseInt(userid),
          pmid: parseInt(pmid),
          report_month: reportMonth,
        })
        .then(res => {
          if (res.data) {
            this.dataList = res.data;
          }
          this.$nextTick(() => {
            setTimeout(() => {
              _this.pdfok = true;
            }, 500);
          });
        });
    },

    getParams(name) {
      let n_url = window.location.href;
      n_url = n_url.replace("/#", "");
      if (n_url.indexOf("?") > 0) {
        n_url = n_url.split("?")[1];
      }
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = n_url.match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    //分割数组 arr：原数组  num：分割后每个数组里的数据条数
    util_spliceArrByN(arr, num) {
      var _arr = [];
      while (arr.length) {
        _arr.push(arr.splice(0, num));
      }
      return _arr;
    },
    //数组按某属性分组
    groupBy(array, f) {
      let groups = {};
      array.forEach(function (o) {
        let group = JSON.stringify(f(o));
        groups[group] = groups[group] || [];
        groups[group].push(o);
      });
      return Object.keys(groups).map(function (group) {
        return groups[group];
      });
    }
  }
};
</script>
<style lang="less" type="text/less">
@import "/public/css/pdf_default";
</style>
